import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Section from "../../styles/styled-component/Section"
import {
  AiFillCloseCircle,
  AiOutlineRight,
  AiOutlineLeft,
} from "react-icons/ai"
import { createRef } from "react"
const LightboxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
const LightboxModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${props => (props.visible ? "1" : "0")};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  z-index: 9999;
`

const LightboxCommandsContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
const LightboxContent = styled.div`
  margin: 15px;
  max-width: 700px;
  position: relative;
`

const PreviewButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
`

const Button = styled.button`
  background: transparent;
  font-size: 3.5rem;
  font-weight: 800;
  color: ${props => (props.disabled ? "transparent" : "white")};
  font-family: sans-serif;
  border: none;
  padding: 1rem;
  cursor: "pointer";
`
const ArrowButton = styled(Button)`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const MobileArrowNavigation = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`
const MobileArrowButton = styled(Button)`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`
const CloseButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
`

export default class LightBox extends Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
  }
  constructor(props) {
    super(props)

    this.boxRef = createRef()
    this.state = {
      showLightbox: false,

      selectedImage: null,
    }
  }

  componentDidMount = () => {
    window.addEventListener("keyup", this.handleKeyUp, false)
  }

  componentWillUnmount = () => {
    window.removeEventListener("keyup", this.handleKeyUp, false)
  }
  handleClick = (e, index) => {
    e.preventDefault()
    this.setState(
      {
        showLightbox: !this.state.showLightbox,
        selectedImage: index,
      },
      () => this.boxRef.current.scrollIntoView({ behavior: "smooth" })
    )
  }

  closeModal = () => {
    this.setState({ showLightbox: false })
  }

  goBack = () => {
    this.setState({ selectedImage: this.state.selectedImage - 1 })
  }

  goForward = () => {
    this.setState({ selectedImage: this.state.selectedImage + 1 })
  }

  handleKeyUp = e => {
    e.preventDefault()
    const { keyCode } = e
    if (this.state.showLightbox) {
      if (keyCode === 37) {
        // Left Arrow Key
        if (this.state.selectedImage > 0) {
          this.setState({ selectedImage: this.state.selectedImage - 1 })
        }
      }
      if (keyCode === 39) {
        // Right Arrow Key
        if (this.state.selectedImage < this.props.images.length - 1) {
          this.setState({ selectedImage: this.state.selectedImage + 1 })
        }
      }
      if (keyCode === 27) {
        // Escape key
        this.setState({ showLightbox: false })
      }
    }
  }
  render() {
    const { images } = this.props
    const { selectedImage, showLightbox } = this.state
    return (
      <Fragment>
        <Section>
          <LightboxContainer>
            {images.map((image, i) => (
              <PreviewButton
                type="button"
                onClick={e => this.handleClick(e, i)}
              >
                {" "}
                <GatsbyImage
                  height={350}
                  image={image.node.childImageSharp.gatsbyImageData}
                />
              </PreviewButton>
            ))}
          </LightboxContainer>{" "}
          {showLightbox && (
            <LightboxModal
              visible={showLightbox}
              onKeyUp={e => this.handleKeyDown(e)}
            >
              {" "}
              <LightboxCommandsContainer>
                {" "}
                <ArrowButton
                  handleClick={this.goBack}
                  onClick={this.goBack}
                  disabled={selectedImage === 0}
                >
                  <AiOutlineLeft />
                </ArrowButton>
                <LightboxContent>
                  <CloseButton
                    ref={this.boxRef}
                    handleClick={this.closeModal}
                    onClick={this.closeModal}
                  >
                    <AiFillCloseCircle />
                  </CloseButton>
                  <GatsbyImage
                    image={
                      images[selectedImage].node.childImageSharp.gatsbyImageData
                    }
                  />
                  <MobileArrowNavigation>
                    {" "}
                    <MobileArrowButton
                      handleClick={this.goBack}
                      onClick={this.goBack}
                      disabled={selectedImage === 0}
                    >
                      <AiOutlineLeft />
                    </MobileArrowButton>
                    <MobileArrowButton
                      handleClick={this.goForward}
                      onClick={this.goForward}
                      disabled={selectedImage === images.length - 1}
                    >
                      <AiOutlineRight></AiOutlineRight>
                    </MobileArrowButton>
                  </MobileArrowNavigation>
                </LightboxContent>{" "}
                <ArrowButton
                  handleClick={this.goForward}
                  onClick={this.goForward}
                  disabled={selectedImage === images.length - 1}
                >
                  <AiOutlineRight></AiOutlineRight>
                </ArrowButton>
              </LightboxCommandsContainer>
            </LightboxModal>
          )}
        </Section>
      </Fragment>
    )
  }
}
