import React from "react"
import { SectionGallery } from "../../styles/styled-component/Section"

import Container from "../../styles/styled-component/Container"

import InnerContainer from "../../styles/styled-component/InnerContainer"
import GalleryComponent from "./gallery"
import { Trans } from "gatsby-plugin-react-i18next"
const GallerySection = () => {
  return (
    <SectionGallery>
      <Container>
        <InnerContainer>
          <h1>
            <Trans>Divas across the world</Trans>
          </h1>
          <p>
            <Trans>
              Movilift's home lift has been installed in more than 50 different
              countries
            </Trans>
          </p>
        </InnerContainer>
      </Container>{" "}
      <GalleryComponent />
    </SectionGallery>
  )
}

export default GallerySection
