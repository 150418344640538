import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import GallerySection from "../components/gallery/"
import { graphql } from "gatsby"
const Gallery = () => {
  return (
    <Layout>
      <Seo title="Gallery | Divas italian home lift " /> <GallerySection />
    </Layout>
  )
}

export default Gallery

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
