import React from "react"
import { StaticQuery, graphql } from "gatsby"
import LightBox from "./lightbox"
const GalleryComponent = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(filter: { dir: { regex: "/images/gallery/" } }) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(height: 700, placeholder: BLURRED)
                }
              }
            }
          }
        }
      `}
      render={data => <LightBox images={data.images.edges} />}
    />
  )
}

export default GalleryComponent
